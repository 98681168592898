import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from "../../components/code-snippet";

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}

	render() {
		return (
			<CodeSnippet
				header={"N-Queens Problem | A Helpful Line-by-Line Code Tutorial"}
				subHeader={""}
				description={
					"The n-queens puzzle is the problem of placing eight chess queens on an 8×8 chessboard so that no two queens threaten each other; thus, a solution requires that no two queens share the same row, column, or diagonal."
				}
				keywords={[]}
				embeddedVideo={"JkP-xats3no"}
				embeddedCode={
					"https://gist.githubusercontent.com/graphoarty/49618b11f3b853d48f470529eb348a3e/raw/25508e5830e5a3148976f89b5a86070dd7510505/gistfile1.txt"
				}
			/>
		);
	}
}

export default View;
